import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import BouncingRambu from "./BouncingRambu";
import ModalOneButton from "./ModalOneButton";
import Logo from "./Logo";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: "#e01250",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#e01250",
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  background: {
    position: "fixed",
    top: "-50%",
    left: "-50%",
    minWidth: "200%",
    minHeight: "200%",
    opacity: "30%",
  },

  bgImg: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "auto",
    minWidth: "50%",
    minHeight: "50%",
    zIndex: "-100",
  },

  logo: {
    position: "fixed",
    top: "45%",
    left: window.innerWidth < 590 ? "50%" : "57%",

    transform: "translate(-50%, -50%)",
  },
  logoTitle: {
    position: "fixed",
    top: "70%",
    left: window.innerWidth < 590 ? "50%" : "57%",
    fontFamily: "Staatliches",
    transform: "translate(-50%, -50%)",
  },
  pic: {
    maxHeight: "300px",
    position: "fixed",
    top: "82%",
    left: window.innerWidth < 590 ? "50%" : "57%",
    fontFamily: "Staatliches",
    zIndex: "-2",

    transform: "translate(-50%, -50%)",
  },
  rambu: {
    display: "flex",
    flexWrap: "wrap",
  },
  description: {
    color: "red",
    fontSize: "50px",
    textShadow: " 2px 2px 5px black ",

    position: "fixed",
    top: "90%",
    left: window.innerWidth < 590 ? "50%" : "57%",
    fontFamily: "Staatliches",
    zIndex: "-2",

    transform: "translate(-50%, -50%)",
    fontFamily: "Staatliches",
  },
}));

const Home = (props) => {
  const [open, setOpen] = useState(false);
  const [rambusNumb, setrambusNum] = useState([]);
  console.log(props);
  const classes = useStyles();

  const [dimesinoW, setDimensionW] = useState(window.innerWidth);

  const updateWidthAndHeight = () => {
    setDimensionW(window.innerWidth);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const newRambu = () => {
    setrambusNum([...rambusNumb, <BouncingRambu />]);
  };

  useEffect(() => {
    // setTimeout(function () {
    //   setOpen(true);
    // }, 5000);
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [window.innerWidth]);

  useEffect(() => {
    setTimeout(function () {
      setOpen(true);
    }, 5000);
  }, []);

  return (
    <div>
      <ModalOneButton
        open={open}
        // func1={handleClickClose}
        func1={handleClickClose}
        text={[
          "Click the logo several times!",
          "Da click en el logo varias veces!",
        ]}
      />
      <main className={classes.content}>
        <Logo newFunc={newRambu} />
      </main>

      {rambusNumb.map((children) => {
        return children;
      })}
    </div>
  );
};

export default Home;
