import React, { useState, useEffect } from "react";
import { useTransition, animated, config } from "react-spring";
import Box from "@material-ui/core/Box";

const img1 = [
  {
    id: 0,
    url: require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/rambu treeWEB.jpg"),
  },
  {
    id: 2,
    url: require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/rambu pele 2WEB.jpg"),
  },
  {
    id: 3,
    url: require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/rambu handsWEB.jpg"),
  },
  {
    id: 4,
    url: require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/rambu cratejpgWEB.jpg"),
  },
];

const img2 = [
  {
    id: 0,
    url: require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/rambu peleWEB.jpg"),
  },
  {
    id: 2,
    url: require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/rambu stockWEB.jpg"),
  },
  {
    id: 3,
    url: require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/rambu pele 3WEB.jpg"),
  },
  {
    id: 4,
    url: require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/recipe rambuWEB.jpg"),
  },
];

const ImagesAboutComp = (props) => {
  const [index, setIndex] = useState(0);

  const chooseImg = () => {
    if (props.val) {
      const img = img1;
      return img;
    } else {
      const img = img2;
      return img;
    }
  };

  const transitions = useTransition(chooseImg()[index], (item) => item.id, {
    from: { display: "none", opacity: 0 },
    enter: { display: "inline-block", opacity: 1 },
    leave: { display: "none", opacity: 0 },
    config: config.molasses,
  });

  useEffect(() => {
    if (props.val) {
    }
  }, []);

  useEffect(
    () =>
      void setInterval(
        () => setIndex((state) => (state + 1) % chooseImg().length),
        4000
      ),
    []
  );
  return (
    <Box
      boxShadow={3}
      style={{ width: "auto", height: "auto", borderRadius: "30px" }}
    >
      {transitions.map(({ item, props, key }) => (
        <animated.img
          key={key}
          style={{
            ...props,

            borderRadius: "10px",
            maxWidth: "100%",

            height: "auto",
          }}
          src={item.url}
          alt="Jungle fruits photos"
        />
      ))}
    </Box>
  );
};

export default ImagesAboutComp;
