import React, { useEffect, useState, useContext } from "react";
import ImagesAboutComp from "./ImagesAboutComp";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { AppContext } from "../utilities/AppContext";
import "./aboutStylesheet.css";
import { useSpring, animated } from "react-spring";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: "#e01250",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#e01250",
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  background: {
    position: "fixed",
    top: "-50%",
    left: "-50%",
    minWidth: "200%",
    minHeight: "200%",
    opacity: "30%",
  },

  banner: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "auto",
    minWidth: "50%",
    minHeight: "50%",
    zIndex: "-99",
    width: "100%",

    height: "300px",
  },

  bgImg: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "auto",
    minWidth: "50%",
    minHeight: "50%",
    zIndex: "-100",
  },

  logo: {
    position: "fixed",
    top: "45%",
    left: window.innerWidth < 590 ? "50%" : "57%",

    transform: "translate(-50%, -50%)",
  },
  logoTitle: {
    position: "fixed",
    top: "70%",
    left: window.innerWidth < 590 ? "50%" : "57%",
    fontFamily: "Staatliches",
    transform: "translate(-50%, -50%)",
  },
  pic: {
    maxHeight: "300px",
    position: "fixed",
    top: "82%",
    left: window.innerWidth < 590 ? "50%" : "57%",
    fontFamily: "Staatliches",
    zIndex: "-2",

    transform: "translate(-50%, -50%)",
  },
  rambu: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    backgroundColor: "light green",

    marginTop: 20,
    width: window.innerWidth - window.innerWidth * 0.2,
    position: "fixed",
    top: "40%",
    left: window.innerWidth < 590 ? "50%" : "57%",
    fontFamily: "Staatliches",
    zIndex: "-2",

    transform: "translate(-50%, -50%)",
  },
  description: {
    color: "red",
    fontSize: "50px",
    textShadow: " 2px 2px 5px black ",

    position: "fixed",
    top: "90%",
    left: window.innerWidth < 590 ? "50%" : "57%",
    fontFamily: "Staatliches",
    zIndex: "-2",

    transform: "translate(-50%, -50%)",
    fontFamily: "'Fredoka One', cursive",
  },
}));

const eng = [
  "Because We’re Not Your Typical Fruit Company.",

  "We are a reliable producer and exporter of unique and specialty tropical fruits.  Our customers include many of the largest supermarket chains, food processing & services companies, and distribution networks.  We work closely with our farmers and customers to deliver only the highest quality, freshest product with exceptional flavor, taste, and extended shelf life….all at the most affordable cost.",

  "Our access to farms across the tropics of Mexico, and Central and South America, enable us to maintain 12 month delivery schedules for many of the fruit products we offer.  We maintain the highest standards with respect to managing the supply chain so that freshly picked fruit can be in the consumer’s hands with on-demand shipping within 12 hours from the time of harvest.",

  "We are constantly improving our engineering to add efficiency to our operations by introducing a variety of uniquely developed, patented technologies for processing & logistics applications.  These applications include:  agro-engineering, field inspection, harvest operations, quality control, washing & cleaning, chilling, packaging, transportation management, customs control, local distribution, in-store display and promotions, and customer support.",
];

const esp = [
  "Porque no somos tu típica empresa de frutas.",

  "Somos un productor y exportador confiable de frutas tropicales únicas y especiales. Nuestros clientes incluyen muchas  cadenas de supermercados, compañías de servicios y procesamiento de alimentos y redes de distribución. Trabajamos en estrecha colaboración con agricultores y clientes para ofrecer la más alta calidad, producto fresco con un sabor excepcional y una vida útil prolongada ... todo al costo más asequible.",

  "Nuestro acceso a granjas en los trópicos de México, América Central y del Sur nos permite mantener una agenda de entrega de 12 meses. Mantenemos los más altos estándares en la gestión de la cadena de suministro para que recién cosechada la fruta puede llegar a manos del consumidor en  12 horas.",

  "Estamos mejorando constantemente nuestra ingeniería para agregar eficiencia a nuestras operaciones mediante la introducción de una variedad de tecnologías patentadas y desarrolladas de forma exclusiva. Estas aplicaciones incluyen: agroingeniería, inspección de campo, operaciones de cosecha, control de calidad, lavado y limpieza, refrigeración, embalaje, gestión del transporte, control de aduanas, distribución local, exhibición en la tienda y promociones, y atención al cliente.",
];

const About = () => {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });

  const langContext = useContext(AppContext);
  const classes = useStyles();
  // console.log(contextLang.engEsp);

  const [dimesinoW, setDimensionW] = useState(window.innerWidth);

  const [text, setText] = useState(eng);

  const [lang, setLang] = useState(langContext.engEsp);

  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      setText(eng);
    } else {
      setText(esp);
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  console.log(text);

  useEffect(() => {
    console.log(langContext.engEsp);
    // setLang(lang === "falseee" ? "trueee" : "falseee");
    chooseLang();
  }, [langContext.engEsp]);

  const updateWidthAndHeight = () => {
    setDimensionW(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  return (
    <animated.div style={props}>
      <div>
        <header
          className="w3-display-container w3-content w3-wide"
          style={{
            maxWidth: "3000px",
          }}
          id="home"
        >
          <img
            className="w3-image"
            src={require("../img/rambu handsWEB.jpg")}
            alt="rabutan tree"
          />

          <h1
            style={{
              textAlign: "center",
              position: "absolute",
              top: dimesinoW < 900 ? "13%" : "22%",
              bottom: "70%",
              left: "50%",
              fontFamily: "Staatliches",
              fontSize: dimesinoW < 700 ? "50px" : "120px",
              color: "white",
              transform: "translate(-50%, -50%)",
              textShadow: "2px 2px 4px black",
            }}
          >
            {chooselll(langContext.engEsp) ? "About Us" : "Quienes Somos"}
          </h1>

          <div className="w3-content" style={{ maxWidth: "1100px" }}>
            <div className="w3-row w3-padding-64" id="about">
              <div className="w3-col m6 w3-padding-large w3-hide-small">
                <div>
                  <ImagesAboutComp />
                </div>
              </div>

              <div className="w3-col m6 w3-padding-large">
                <h1 className="w3-center">
                  {chooselll(langContext.engEsp)
                    ? "Why Choose Us?"
                    : "¿Por qué elegirnos?"}
                </h1>
                <br />

                <p className="w3-large">{text[0]}</p>
                <br />
                <p className="w3-large  ">{text[1]}</p>
                <br />
                <p className="w3-large  ">{text[2]}</p>
                <br />
                <p className="w3-large  ">{text[3]}</p>
              </div>
            </div>
          </div>
        </header>
      </div>
    </animated.div>
  );
};

export default About;
