import React, { useState, useEffect } from "react";
import { useTransition, animated, config } from "react-spring";
import Box from "@material-ui/core/Box";

const img = [
  { id: 0, url: require("../img/rambu washWEB.jpg") },
  { id: 2, url: require("../img/rambu proce.jpeg") },
  { id: 3, url: require("../img/rambu cratejpgWEB.jpg") },
  { id: 4, url: require("../img/rambu cajaWEB.jpg") },
];

const ImagesAboutComp = () => {
  const [index, setIndex] = useState(0);

  const transitions = useTransition(img[index], (item) => item.id, {
    from: { display: "none", opacity: 0 },
    enter: { display: "inline-block", opacity: 1 },
    leave: { display: "none", opacity: 0 },
    config: config.molasses,
  });

  useEffect(
    () => void setInterval(() => setIndex((state) => (state + 1) % 4), 4000),
    []
  );
  return (
    <Box
      boxShadow={3}
      style={{ width: "auto", height: "auto", borderRadius: "30px" }}
    >
      {transitions.map(({ item, props, key }) => (
        <animated.img
          key={key}
          style={{
            ...props,

            borderRadius: "10px",
            maxWidth: "100%",

            height: "auto",
          }}
          src={item.url}
          alt="Jungle fruits photos"
        />
      ))}
    </Box>
  );
};

export default ImagesAboutComp;
