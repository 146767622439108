import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withRouter, Link } from "react-router-dom";
import { Button } from "@material-ui/core";
// import Link from "@material-ui/core/Link";

import { ShakeRotate } from "reshake";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  linkText: {
    color: "white",
    textDecoration: "none",
    "&:hover": {
      color: "yellow",

      textDecoration: "none",
    },
  },

  linkText2: {
    color: "black",
    textDecoration: "none",
    "&:hover": {
      color: "#e01250",

      textDecoration: "none",
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  typo: {
    fontFamily: "Staatliches",
  },
}));
const RecipeCard = ({
  title,
  calories,
  image,
  ingredients,
  website,
  serves,
  directions,
  multTitles,
  mulIngrid,
  mulDire,
  cardTitles,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  console.log("directions", directions);
  const linkWebsite = new URL(`${website}`).hostname;

  return (
    <div className="row">
      <div className="col-sm-6">
        <div
          className="card shadow"
          style={{
            width: "22em",
            flexBasis: "15em",
          }}
        >
          <Card className={classes.root}>
            <Typography
              style={{
                fontFamily: "Staatliches",
                padding: "10px",

                color: "white",
                backgroundColor: "#e01250",
                letterSpacing: "1px",
              }}
            >
              {" "}
              {title}{" "}
            </Typography>
            <CardMedia
              className={classes.media}
              image={image}
              title="Rambutan recipe"
            />
            <CardContent>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{
                  fontFamily: "Staatliches",
                  letterSpacing: "1px",
                }}
              >
                {cardTitles[1]}: {Math.round(calories)}
                <br />
                {cardTitles[0]}: {serves}
                {/* <br />
                recipe from:{" "}
                <Link
                  target="_blank"
                  href={linkWebsite}
                  className={classes.linkText2}
                >
                  <ShakeRotate>
                    {new URL(`${website}`).hostname
                      .replace(/^www\./, "")
                      .split(".")
                      .slice(0, -1)
                      .join(".")}
                  </ShakeRotate>
                </Link> */}
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <Link
                to={{
                  pathname: `/${title}`,
                  state: {
                    title,
                    website,
                    serves,
                    calories,
                    ingredients,
                    image,
                    directions,
                    multTitles,
                    mulIngrid,
                    mulDire,
                  },
                }}
                className={classes.linkText}
              >
                <div>
                  <Button
                    aria-label="recipe"
                    style={{
                      backgroundColor: "",
                      fontFamily: "Staatliches",
                      letterSpacing: "1px",
                      color: "white",
                      backgroundColor: "#e01250",
                    }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <ShakeRotate className={classes.typo}>
                      {cardTitles[2]}
                    </ShakeRotate>
                  </Button>
                </div>
              </Link>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <ul>
                  {ingredients.map((ingredient, i) => (
                    <li
                      key={ingredients.length / i - 3}
                      style={{
                        fontFamily: "Staatliches",
                        letterSpacing: "1px",
                      }}
                    >
                      {ingredient}.
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Collapse>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default RecipeCard;
