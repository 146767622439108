import React, { useEffect, useState } from "react";
import { Howl, Howler } from "howler";
import { ShakeSlow } from "reshake";
const Logo = (props) => {
  const [dimesinoW, setDimensionW] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  const updateWidthAndHeight = () => {
    setDimensionW(window.innerWidth);
  };

  const sound = new Howl({
    src: [require("../img/465264__rvgerxini__quick-pop.mp3")],
    volume: 0.5,
  });

  const chooseLogo = (width) => {
    let style = {
      position: "fixed",
      top: "50%",
      left: "50%",

      transform: "translate(-50%, -45%)",
    };

    const rambutans = () => {
      sound.play();

      props.newFunc();
    };

    const componentMake = (ratio) => {
      return (
        <div style={style} onClick={rambutans}>
          <ShakeSlow>
            <img
              src={require("../img/icon logo jungle fruit300dpi.png")}
              style={{
                width: `${window.innerWidth / ratio}px`,

                userDrag: "none",
                userSelect: "none",
                mozUserSelect: "none",
                webkitUserDrag: "none",
                webkitUserSelect: "none",
                msUserSelect: "none",
              }}
            />
          </ShakeSlow>
        </div>
      );
    };

    if (width < 590) {
      return componentMake(1.5);
    } else if (width < 850) {
      return componentMake(4);
    } else {
      return componentMake(3.7);
    }
  };

  return chooseLogo(dimesinoW);
};

export default Logo;
