import React, { useEffect, useState, useContext } from "react";
import ImagesAboutComp from "../ImagesAboutComp";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ImagesRambutanAbout1 from "./ImagesRambutanAbout1";
import { AppContext } from "../../utilities/AppContext";
import "../aboutStylesheet.css";
import { useSpring, animated } from "react-spring";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: "#e01250",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#e01250",
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  background: {
    position: "fixed",
    top: "-50%",
    left: "-50%",
    minWidth: "200%",
    minHeight: "200%",
    opacity: "30%",
  },

  banner: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "auto",
    minWidth: "50%",
    minHeight: "50%",
    zIndex: "-99",
    width: "100%",

    height: "300px",
  },

  bgImg: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "auto",
    minWidth: "50%",
    minHeight: "50%",
    zIndex: "-100",
  },

  logo: {
    position: "fixed",
    top: "45%",
    left: window.innerWidth < 590 ? "50%" : "57%",

    transform: "translate(-50%, -50%)",
  },
  logoTitle: {
    position: "fixed",
    top: "70%",
    left: window.innerWidth < 590 ? "50%" : "57%",
    fontFamily: "Staatliches",
    transform: "translate(-50%, -50%)",
  },
  pic: {
    maxHeight: "300px",
    position: "fixed",
    top: "82%",
    left: window.innerWidth < 590 ? "50%" : "57%",
    fontFamily: "Staatliches",
    zIndex: "-2",

    transform: "translate(-50%, -50%)",
  },
  rambu: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    backgroundColor: "light green",

    marginTop: 20,
    width: window.innerWidth - window.innerWidth * 0.2,
    position: "fixed",
    top: "40%",
    left: window.innerWidth < 590 ? "50%" : "57%",
    fontFamily: "Staatliches",
    zIndex: "-2",

    transform: "translate(-50%, -50%)",
  },
  description: {
    color: "red",
    fontSize: "50px",
    textShadow: " 2px 2px 5px black ",

    position: "fixed",
    top: "90%",
    left: window.innerWidth < 590 ? "50%" : "57%",
    fontFamily: "Staatliches",
    zIndex: "-2",

    transform: "translate(-50%, -50%)",
    fontFamily: "'Fredoka One', cursive",
  },
}));

const eng = [
  "An exotic fruit native to tropical environments. The fruit is related to the lychee and has a similar appearance when peeled. Its translucent white flesh has a sweet yet creamy taste and contains a single, inedible seed.",
  "The flesh texture is similar to grapes with a slight strawberry quality. It has a pleasant fragrance that is desired in some cooked dishes.",
  "Rambutan is high in vitamin C, copper, manganese, and contains trace elements of many other nutrients such as potassium, calcium, and iron. The fruit has many medicinal qualities including many antioxidants for cancer prevention, and improved digestion and kidney function. It is a favorite treat of many cultures.",
  "Cut the skin with a sharp knife or twisted open the skin between your hands. Now peel the skin to reveal the beautiful egg-like rambutan fruit.",
  "The hairs aren't sharp, but be gentle so you don't break the fruit. Remove and discard the nut-like seed in the center of the flesh. Or pop the whole fruit into your mouth and spit out the seed if you prefer.",
  "If you need to store rambutan, wrap them in a paper towel and put them in a perforated plastic bag and place them in the high humidity section of your refrigerator. Don't peel them until just before you are going to serve them or cook with them. Enjoy eating your fresh rambutan as a snack or fresh fruit dessert. Rambutan makes an excellent addition to a fresh tropical fruit salad.",
];

const esp = [
  "Una fruta exótica nativa de ambientes tropicales. La fruta está relacionada con el lichi y tiene una apariencia similar cuando se pela. Su pulpa blanca translúcida tiene un sabor dulce pero cremoso y contiene solo una semilla no comestible.",

  "La textura de la carne es similar a la de las uvas con una ligera calidad de fresa. Tiene una fragancia agradable.",

  "El rambután es rico en vitamina C, cobre, manganeso y contiene muchos otros nutrientes como potasio, calcio y hierro. La fruta tiene muchas cualidades medicinales, por ejemplo: antioxidantes para la prevención del cáncer y una mejor digestión y función renal. Es el majar favorito de muchas culturas.",

  "Corta la piel con un cuchillo o abre la cascara con tus manos. Despues pela la cascara para revelar la hermosa fruta.",

  "Los pelos no son afilados, pero sé suave para no romper la fruta. Retira y desecha la semilla. O pon toda la fruta en tu boca y escupe la semilla si prefieres.",
  "Si necesita almacenar los rambutánes, envuélvalos en una toalla de papel y póngalos en una bolsa de plástico perforada y colóquelos en la sección de alta humedad de su refrigerador. No los pele hasta justo antes de servirlos. Disfrute comiendo su rambután fresco como refrigerio o postre de fruta fresca. El rambután es una excelente adición a una ensalada fresca de frutas tropicales.",
];

const AboutRambutan = () => {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });

  const langContext = useContext(AppContext);
  const classes = useStyles();

  const [lang, setLang] = useState(langContext.engEsp);
  const [dimesinoW, setDimensionW] = useState(window.innerWidth);
  console.log(dimesinoW);
  const [text, setText] = useState(eng);
  const updateWidthAndHeight = () => {
    setDimensionW(window.innerWidth);
  };
  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      setText(eng);
    } else {
      setText(esp);
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  console.log(text);

  useEffect(() => {
    console.log(langContext.engEsp);

    chooseLang();
  }, [langContext.engEsp]);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  return (
    <animated.div style={props}>
      <div>
        <header
          className="w3-display-container w3-content w3-wide"
          style={{
            maxWidth: "3000px",
          }}
          id="home"
        >
          <img
            className="w3-image"
            src={require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/rambu peleWEB.jpg")}
            alt="Hamburger Catering"
          />

          <h1
            style={{
              position: "absolute",
              top: dimesinoW < 590 ? "15%" : "25%",
              bottom: "70%",
              left: "50%",
              fontFamily: "Staatliches",
              fontSize: dimesinoW < 700 ? "50px" : "120px",
              color: "white",
              transform: "translate(-50%, -50%)",
              textShadow: "2px 2px 4px black",
            }}
          >
            {chooselll(langContext.engEsp) ? "Rambutans" : "Rambutanes"}
          </h1>

          <div className="w3-content" style={{ maxWidth: "1100px" }}>
            <div className="w3-row w3-padding-64" id="about">
              <div className="w3-col m6 w3-padding-large w3-hide-small">
                <div>
                  <ImagesRambutanAbout1 val={true} />
                </div>
              </div>

              <div className="w3-col m6 w3-padding-large">
                <h1 className="w3-center">
                  {chooselll(langContext.engEsp)
                    ? "What is a Rambutan?"
                    : "¿Que es un Rambutan?"}
                </h1>
                <br />

                <p className="w3-large">{text[0]}</p>

                <p className="w3-large  w3-hide-medium">{text[1]}</p>
                <p className="w3-large  w3-hide-medium">{text[2]}</p>
              </div>
            </div>
            <div className="w3-row w3-padding-64" id="about">
              <div className="w3-col m6 w3-padding-large">
                <h1 className="w3-center">
                  {chooselll(langContext.engEsp)
                    ? "how to eat a rambutan? "
                    : "¿Como comer un rambutan?"}
                </h1>
                <br />

                <p className="w3-large">{text[3]}</p>

                <p className="w3-large  w3-hide-medium">{text[4]}</p>
                <p className="w3-large  w3-hide-medium">{text[5]}</p>
              </div>

              <div className="w3-col m6 w3-padding-large w3-hide-small">
                <div>
                  <ImagesRambutanAbout1 val={false} />
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* <div className={classes.bgImg}>
          <img
            src={require("../img/leaves bg.png")}
            className={classes.background}
          />
        </div> */}
      </div>
    </animated.div>
  );
};

export default AboutRambutan;
