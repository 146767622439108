import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import { AppContext } from "../utilities/AppContext";
import { ShakeRotate, ShakeVertical, ShakeLittle } from "reshake";
import { GiAbstract016 } from "react-icons/gi";

import { IoMdContact, IoMdInformationCircleOutline } from "react-icons/io";
import { RiLightbulbLine } from "react-icons/ri";

import Link from "@material-ui/core/Link";

import { withStyles } from "@material-ui/core/styles";

import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#e01250",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#e01250",
    fontFamily: "Staatliches",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  linkText: {
    color: "white",
    textDecoration: "none",
    "&:hover": {
      color: "yellow",

      textDecoration: "none",
    },
  },

  linkText2: {
    marginTop: "auto",
    bottom: 0,
    color: "white",
    textDecoration: "none",
    "&:hover": {
      color: "yellow",

      textDecoration: "none",
    },
  },
  navIcon: {
    color: "white",
    // size: "30px",
  },
  itemListText: {
    color: "white",
  },
  typo: {
    fontFamily: "Staatliches",
  },

  lang: {
    fontFamily: "Staatliches",
    marginLeft: "auto",
    float: "right",
  },

  navTex: {
    display: "flex",
  },
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: "red",
    "&$checked": {
      transform: "translateX(12px)",
      color: "red",
      "& + $track": {
        opacity: 1,
        backgroundColor: "white",
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "white",
  },
  checked: {},
}))(Switch);

const icons = [
  IoMdInformationCircleOutline,

  GiAbstract016,
  RiLightbulbLine,
  IoMdContact,
];

const titles = {
  0: "Quienes Somos",

  1: "Rambutanes",
  2: "Ideas para Recetas",
  3: "Contacto",
};

const titlesEng = {
  0: "About us",

  1: "Rambutans",
  2: "Recipe ideas",
  3: "Contact",
};

export default function NavBar2() {
  const langContext = useContext(AppContext);

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("lex"));

  const history = useHistory();

  const handleRoute = (path) => {
    history.push(path);
  };

  useEffect(() => {
    localStorage.setItem("lex", lang);
    langContext.setengEsp(lang);
    console.log("current lang", lang);
  }, [lang]);

  const handleChange = () => {
    setLang(lang === "falseee" ? "trueee" : "falseee");
  };
  const chooseLang = () => {
    if (chooselll(lang)) {
      const x = titlesEng;
      return x;
    } else {
      const x = titles;
      return x;
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />

      <List>
        {icons.map((Item, index) => {
          return (
            <ListItem key={index + 5} button index={chooseLang()[index]}>
              <ListItemIcon key={index + 18}>
                {
                  <Item
                    key={index + 35}
                    className={classes.navIcon}
                    size="30px"
                  />
                }
              </ListItemIcon>

              <Link
                href={titlesEng[index].split(" ").join("").toLowerCase()}
                key={chooseLang()[index]}
                className={classes.linkText}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <Typography
                  key={index + 532}
                  className={classes.typo}
                  variant="h6"
                  noWrap
                >
                  <ShakeRotate key={index + 3239}>
                    {chooseLang()[index]}
                  </ShakeRotate>
                </Typography>
              </Link>
              {/* <ListItemText primary={titles[index]} /> */}
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ marginLeft: "10px" }}>
            <Link href="/" className={classes.linkText}>
              <ShakeVertical>
                {" "}
                <img
                  src={require("../img/junglefruiticonlogogood.png")}
                  style={{ height: "47px" }}
                />
              </ShakeVertical>
            </Link>
          </div>

          {open === true ? null : (
            <Typography component="div" className={classes.lang}>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid
                  item
                  style={{
                    color: "yellow",
                  }}
                >
                  {chooselll(lang) ? "eng" : "esp"}
                </Grid>
                <Grid item>
                  <AntSwitch
                    checked={chooselll(lang)}
                    onChange={handleChange}
                    name="checkedC"
                  />
                </Grid>
                {/* <Grid
                  item
                  style={{
                    color: chooselll(lang) ? "yellow" : "white",
                  }}
                >
                  Eng
                </Grid> */}
              </Grid>
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon
                style={{
                  color: "white",
                }}
              />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        {drawer}
      </Drawer>
      <Divider />
      {/* <Typography>
        <Link
          className={classes.linkText2}
          target="_blank"
          href="https://luis-ascencio-portfolio.firebaseapp.com/"
        >
          <ShakeLittle>created by Luis Ascencio</ShakeLittle>
        </Link>
      </Typography> */}
    </div>
  );
}
