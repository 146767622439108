import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";

import { withRouter, Link } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ShakeRotate, ShakeLittle } from "reshake";
import { AppContext } from "../utilities/AppContext";
const useStyles = makeStyles((theme) => ({
  media: {
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  linkText: {
    color: "white",
    textDecoration: "none",
    "&:hover": {
      color: "yellow",

      textDecoration: "none",
    },
  },

  linkText2: {
    color: "black",
    textDecoration: "none",
    "&:hover": {
      color: "#e01250",

      textDecoration: "none",
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  typo: {
    fontFamily: "Staatliches",
  },
  x: {
    color: "black",
    textDecoration: "none",
    "&:hover": {
      color: "#e01250",

      textDecoration: "none",
    },
  },
}));
const RecipeInstructions = (props) => {
  console.log(props.location.state);
  const classes = useStyles();
  const [dirOrIng, setDirOrIng] = useState(false);
  const [open, setOpen] = useState(false);
  const [dimesinoW, setDimensionW] = useState(window.innerWidth);
  const langContext = useContext(AppContext);
  const [titlesLang, setTitlesLang] = useState(0);

  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      setTitlesLang(0);
    } else {
      setTitlesLang(1);
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  useEffect(() => {
    console.log(langContext.engEsp);
    chooseLang();
    // setLang(lang === "falseee" ? "trueee" : "falseee");
  }, [langContext.engEsp]);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  const updateWidthAndHeight = () => {
    setDimensionW(window.innerWidth);
  };
  const handleDirOrIngre = () => {
    setDirOrIng(!dirOrIng);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const chooseInfo = () => {
    if (dirOrIng) {
      return info.mulDire[titlesLang];
    } else {
      return info.mulIngrid[titlesLang];
    }
  };

  const chooseButtonsLang = () => {
    if (chooselll(langContext.engEsp)) {
      return ["ingredients", "directions"];
    } else {
      return ["ingredientes", "instrucciones"];
    }
  };

  const info = props.location.state;
  console.log(info.website);

  //   const linkWebsite = new URL(`${website}`).hostname;

  const chooseCard = (width) => {
    const componentMake = (ratio) => {
      return (
        <div style={{ margin: "auto", overflow: "scroll" }}>
          <Card
            style={{
              width: window.innerWidth / 1.2,
              maxWidth: 800,
              position: "absolute",
              padding: "auto",
              // marginTop: `${window.innerHeight / ratio}px`,
              marginTop: `${ratio}px`,
              // top: `500px`,
              left: "50%",
              // bottom: `${window.innerHeight / ratio}px`,
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography
              style={{
                fontFamily: "Staatliches",
                padding: "10px",

                color: "white",
                backgroundColor: "#e01250",
                letterSpacing: "1px",
              }}
            >
              {" "}
              {info.multTitles[titlesLang]}{" "}
            </Typography>
            <CardMedia
              className={classes.media}
              image={info.image}
              title="Rambutan recipe"
            />
            <CardContent>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{
                  fontFamily: "Staatliches",
                  letterSpacing: "1px",
                }}
              >
                {chooselll(langContext.engEsp) ? "calories:" : "calorias:"}{" "}
                {Math.round(info.calories)}
                <br />
                {chooselll(langContext.engEsp)
                  ? "portions:"
                  : "porciones:"}{" "}
                {info.serves}
                <br />
                {chooselll(langContext.engEsp)
                  ? "recipe from:"
                  : "receta de:"}{" "}
                <a
                  target="_blank"
                  href={info.website}
                  className={classes.linkText2}
                >
                  <ShakeLittle>
                    {new URL(`${info.website}`).hostname
                      .replace(/^www\./, "")
                      .split(".")
                      .slice(0, -1)
                      .join(".")}
                  </ShakeLittle>
                </a>
              </Typography>
            </CardContent>

            <CardActions>
              <Button
                style={{ marginLeft: " auto" }}
                onClick={handleDirOrIngre}
                style={{
                  backgroundColor: "",
                  fontFamily: "Staatliches",
                  letterSpacing: "1px",
                  color: "white",
                  backgroundColor: "#e01250",
                  marginLeft: "auto",
                }}
              >
                <Link
                  className={classes.linkText}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <ShakeRotate className={classes.typo}>
                    {dirOrIng ? chooseButtonsLang()[0] : chooseButtonsLang()[1]}
                  </ShakeRotate>
                </Link>
              </Button>
            </CardActions>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <CardContent style={{ maxHeight: "200px" }}>
                <div style={{ display: "flex" }}>
                  <ShakeRotate className={classes.x}>
                    <CloseIcon
                      style={{ padding: "auto" }}
                      onClick={handleClose}
                    />
                  </ShakeRotate>
                  <Typography className={classes.typo}>
                    {" "}
                    {dirOrIng ? chooseButtonsLang()[1] : chooseButtonsLang()[0]}
                    :
                  </Typography>
                </div>
                <Grid>
                  <ul
                    style={{
                      overflowY: "auto",
                      maxHeight: "200px",

                      paddingBottom: "50px",
                    }}
                  >
                    <br />
                    {chooseInfo().map((ingredient, i) => (
                      <li
                        key={info.ingredients.length / i - 3}
                        style={{
                          fontFamily: "Staatliches",
                          letterSpacing: "1px",
                        }}
                      >
                        {ingredient}.
                      </li>
                    ))}
                  </ul>
                </Grid>
              </CardContent>
            </Collapse>
          </Card>
        </div>
      );
    };

    if (width < 590) {
      return componentMake(350);
    } else if (width < 850) {
      return componentMake(555);
    } else {
      return componentMake(560);
    }
  };

  return chooseCard(dimesinoW);
};

export default RecipeInstructions;
