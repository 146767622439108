import React, { useState, useEffect } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "../views/Home";
import About from "../views/About";
import ContactPage from "../views/contact/ContactPage";
import RecipeInstructions from "../views/RecipeInstructions";

import AboutRambutan from "../views/aboutRambu/AboutRambutan";
import RecipesIdeas from "../views/RecipesIdeas";
import {
  CssBaseline,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

function Routes() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />

      <Router>
        <Switch>
          <Route exact path="/" component={Home} />

          <Route exact path="/aboutus" component={About} />

          <Route exact path="/contact" component={ContactPage} />

          <Route exact path="/rambutans" component={AboutRambutan} />
          <Route exact path="/recipeideas" component={RecipesIdeas} />
          <Route exact path="/:id" component={RecipeInstructions} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
}

export default Routes;
