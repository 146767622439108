import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import ContactPhone from "./ContactPhone";
import ContactDesktop from "./ContactDesktop";
const ContactPage = () => {
  const [dimesinoW, setDimensionW] = useState(window.innerWidth);
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  const updateWidthAndHeight = () => {
    setDimensionW(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  return (
    <div>
      {window.innerWidth < 700 ? (
        <ContactPhone />
      ) : (
        <animated.div style={props}>
          <ContactDesktop />
        </animated.div>
      )}
    </div>
  );
};

export default ContactPage;
