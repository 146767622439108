import React, { useEffect, useState, useContext } from "react";

import Chance from "chance";

import { AppContext } from "../utilities/AppContext";

var chance = new Chance();
export default function BouncingText(props) {
  console.log(" bouncing tetx", props.text);
  const langContext = useContext(AppContext);

  const [numb, setNumb] = useState(0);
  const [hovver, setHovver] = useState(false);
  const [lang, setLang] = useState(langContext.engEsp);

  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      setNumb(0);
    } else {
      setNumb(1);
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  useEffect(() => {
    console.log(langContext.engEsp);
    // setLang(lang === "falseee" ? "trueee" : "falseee");
    chooseLang();
  }, [langContext.engEsp]);

  const text = [
    [
      "This Tropical Fruit Tree Can Grow to over 75Feet",
      "Este árbol frutal tropical puede crecer hasta más de 75 pies",
    ],

    [
      "Its Crushed Leaves Make a paste To Nourish Hair",
      "Sus hojas machacadas hacen una pasta para nutrir el cabello",
    ],

    [
      "The Fruit Stimulated Red Blood Cell Production",
      "La fruta estimula la producción de glóbulos rojos",
    ],

    [
      "Its Bark Treats Tongue Disorders",
      "Su corteza trata los trastornos de la lengua",
    ],

    ["In Vietnam it’s called Chom Chom", "En Vietnam se llama Chom Chom"],

    [
      "It’s Peel Has Many Medicinal Uses",
      "Su cáscara tiene muchos usos medicinales",
    ],

    ["It Can Prevent Weight Gain", "Puede prevenir el aumento de peso"],

    [
      "Its Seed Produces Specialty Fat For Cosmetics",
      "Su semilla produce grasas especiales para cosméticos",
    ],

    [
      "Its Hair and Skin Make Specialty Charcoal For Industrial Uses",
      "Su cabello y piel hacen carbón especial para usos industriales",
    ],
  ];

  return (
    <h1
      onMouseOver={() => {
        setHovver(true);
      }}
      onMouseOut={() => {
        setHovver(false);
      }}
      style={{
        userDrag: "none",
        userSelect: "none",
        mozUserSelect: "none",
        webkitUserDrag: "none",
        webkitUserSelect: "none",
        msUserSelect: "none",
        zIndex: hovver ? "0" : "-98",
        position: "fixed",
        textAlign: "center",
        fontSize: hovver ? "4.5vw" : "4vw",
        color: hovver ? "#ffee00 " : "#e01250",
        textShadow:
          "0 1px 0 #ccc,  0 2px 0 #c9c9c9,  0 3px 0 #bbb, 0 4px 0 #b9b9b9,  0 5px 0 #aaa,  0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2),  0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2),   0 20px 20px rgba(0,0,0,.15)",

        fontFamily: "Staatliches",
        top: `${props.top}px`,
        left: `${props.left}px`,

        "&:hover": {
          zIndex: "1",
        },
      }}
    >
      {props.text[numb]}
    </h1>
  );
}
