import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from "@material-ui/icons/Email";
import "./ContactCardStyle.css";
import LanguageIcon from "@material-ui/icons/Language";
import PhoneIcon from "@material-ui/icons/Phone";
import Link from "@material-ui/core/Link";
import { ShakeRotate } from "reshake";
import { AppContext } from "/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/utilities/AppContext.js";
// console.log(localStorage.getItem("lex"));
const useStyles = makeStyles({
  root: {
    maxWidth: 845,
    height: 600,
    position: "fixed",
    top: "45%",
    left: "50%",

    transform: "translate(-50%, -50%)",
  },
  background: {
    position: "fixed",
    top: "-50%",
    left: "-50%",
    minWidth: "200%",
    minHeight: "200%",
    opacity: "30%",
  },

  bgImg: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "auto",
    minWidth: "50%",
    minHeight: "50%",
    zIndex: "-100",
  },

  whiteText: {
    color: "white",
    fontFamily: "Staatliches",
  },

  blackText: {
    fontFamily: "Staatliches",
    marginTop: "-10px",
  },
  blackIcons: {
    marginRight: "5px",
    display: "inline-block",
    fontFamily: "Staatliches",
    marginTop: "-10px",
  },

  location: {
    marginBottom: "-10px",
  },

  linkText: {
    display: "inline-block",
    fontSize: "12px",
    color: "white",
    fontFamily: "Staatliches",
    textDecoration: "none",
    "&:hover": {
      color: "yellow",

      textDecoration: "none",
    },
  },
});
const titlesEng = [
  "Engineering & International Customer Support",
  "Logistics & Packing/Processing",
  "Field Research & Packing/Processing",
  "usa",
];

const titles = [
  "Ingeniería y atención al cliente internacional",
  "Logística y embalaje / procesamiento",
  "Investigación de campo y procesamiento",
  "estados unidos",
];
export default function ContactPhone() {
  const classes = useStyles();

  const langContext = useContext(AppContext);

  const [lang, setLang] = useState(langContext.engEsp);

  useEffect(() => {
    console.log(langContext.engEsp);
    setLang(lang === "falseee" ? "trueee" : "falseee");
  }, [langContext.engEsp]);

  // const handleChange = () => {
  //   setLang(lang === "falseee" ? "trueee" : "falseee");
  // };
  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      const x = titlesEng;
      return x;
    } else {
      const x = titles;
      return x;
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  return (
    <div>
      <aside className="profile-card">
        <header>
          <img
            src={require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/icon logo jungle fruit300dpi.png")}
            style={{
              maxWidth: "60px",
            }}
          />
        </header>

        <div className="profile-bio">
          <div className={classes.blackText}> {chooseLang()[3]}:</div>
          <div className={classes.whiteText}>
            {chooseLang()[0]}
            <div className={classes.location}>Dresden, Maine</div>
          </div>
          <hr />
          <div className={classes.blackText}>Mexico:</div>
          <div className={classes.whiteText}>
            {chooseLang()[1]}
            <div className={classes.location}>Tapachula, Chiapas</div>
          </div>
          <hr />
          <div className={classes.blackText}> Honduras:</div>
          <div className={classes.whiteText}>
            {chooseLang()[2]}
            <div className={classes.location}>La Ceiba, San Pedro Sula</div>
          </div>

          <hr />
          <div style={{ marginTop: "-10px" }}>
            <div>
              <div className={classes.blackIcons}>
                <EmailIcon />
              </div>
              <div className={classes.linkText}>
                <ShakeRotate>
                  <Link
                    className={classes.linkText}
                    href="mailto:info@junglefruit.net "
                  >
                    <h5 className={classes.linkText}>info@junglefruit.net</h5>
                  </Link>
                </ShakeRotate>
              </div>
            </div>

            <div>
              <div className={classes.blackIcons}>
                <PhoneIcon />
              </div>
              <div className={classes.linkText}>
                <ShakeRotate>
                  <Link
                    className={classes.linkText}
                    href="tel: +1 781 492-6128"
                  >
                    <h5 className={classes.linkText}>+1 781 492-6128</h5>
                  </Link>
                </ShakeRotate>
              </div>
            </div>

            <div>
              <div className={classes.blackIcons}>
                <LanguageIcon />
              </div>
              <div className={classes.linkText}>
                <ShakeRotate>
                  <Link className={classes.linkText} href="www.junglefruit.net">
                    <h5 className={classes.linkText}>www.junglefruit.net</h5>
                  </Link>
                </ShakeRotate>
              </div>
            </div>

            <div>
              <div className={classes.blackIcons}></div>
              <div className={classes.linkText}></div>
            </div>
            <div>
              <div className={classes.blackIcons}></div>
              <div className={classes.linkText}></div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
}
