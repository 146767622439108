import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { AppContext } from "../utilities/AppContext";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    margin: 5,
    background: "#e01250",
    fontFamily: "Staatliches",
    color: "white",
    "&:hover": {
      color: "yellow",
      background: "#e01250",
      textDecoration: "none",
    },
  },

  text: {
    textAlign: "center",
    fontSize: "30px",
    color: "#e01250",
    textShadow: "2px 2px 5px black",

    fontFamily: "Staatliches",
  },
}));
const ModalOneButton = (props) => {
  const langContext = useContext(AppContext);

  const [numb, setNumb] = useState(0);

  const [lang, setLang] = useState(langContext.engEsp);

  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      setNumb(0);
    } else {
      setNumb(1);
    }

    console.log("nummm", numb);
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  useEffect(() => {
    chooseLang();
  }, [langContext.engEsp]);
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.func1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography className={classes.text}> {props.text[numb]}</Typography>
        </DialogTitle>

        <DialogActions>
          <Button onClick={props.func1} className={classes.submit} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalOneButton;
