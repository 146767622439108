import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import EmailIcon from "@material-ui/icons/Email";
import "./ContactCardStyle.css";
import LanguageIcon from "@material-ui/icons/Language";
import PhoneIcon from "@material-ui/icons/Phone";
import Link from "@material-ui/core/Link";
import { ShakeRotate } from "reshake";
import { AppContext } from "/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/utilities/AppContext.js";
import { Card, CardMedia, CardContent, Typography } from "@material-ui/core";
// console.log(localStorage.getItem("lex"));
const useStyles = makeStyles({
  root: {
    // width: `${window.innerWidth - window.innerWidth * 0.35}px`,
    // height: `${window.innerHeight - window.innerWidth * 0.25}px`,
    position: "absolute",
    top: "53.5%",
    left: "50%",
    backgroundColor: "#e01250",
    transform: "translate(-50%, -50%)",
  },

  whiteText: {
    color: "white",
    textAlign: "center",
    fontFamily: "Staatliches",
    fontSize: "1.9vw",
  },

  blackText: {
    textAlign: "center",
    fontSize: "2.5vw",

    fontFamily: "Staatliches",
    marginTop: "-10px",
  },
  blackIcons: {
    fontSize: "1.6vw",
    marginRight: "5px",
    display: "inline-block",
    fontFamily: "Staatliches",
    marginTop: "5px",
  },

  location: {
    fontSize: "1.3vw",
    marginBottom: "-10px",
    fontFamily: "Staatliches",
  },

  linkText: {
    fontSize: "1.2vw",
    textAlign: "center",
    display: "inline-block",

    color: "white",
    fontFamily: "Staatliches",
    textDecoration: "none",
    "&:hover": {
      color: "yellow",

      textDecoration: "none",
    },
  },
});
const titlesEng = [
  "Engineering & International Customer Support",
  "Logistics & Packing/Processing",
  "Field Research & Packing/Processing",
  "usa",
];

const titles = [
  "Ingeniería y atención al cliente internacional",
  "Logística y embalaje / procesamiento",
  "Investigación de campo y procesamiento",
  "estados unidos",
];
export default function ContactPhone() {
  const classes = useStyles();

  const langContext = useContext(AppContext);

  const [lang, setLang] = useState(langContext.engEsp);

  useEffect(() => {
    console.log(langContext.engEsp);
    setLang(lang === "falseee" ? "trueee" : "falseee");
  }, [langContext.engEsp]);

  // const handleChange = () => {
  //   setLang(lang === "falseee" ? "trueee" : "falseee");
  // };
  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      const x = titlesEng;
      return x;
    } else {
      const x = titles;
      return x;
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  return (
    <Card className={classes.root}>
      <header style={{ top: "-50%", left: "-50%" }}>
        {/* <img
            src={require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/icon logo jungle fruit300dpi.png")}
            style={{
              maxWidth: "60px",
            }}
          /> */}
      </header>

      <CardContent>
        <br />
        <Typography className={classes.blackText}>
          {" "}
          {chooseLang()[3]}:
        </Typography>
        <Typography className={classes.whiteText}>
          {chooseLang()[0]}
          <Typography className={classes.location}>Dresden, Maine</Typography>
        </Typography>
        <br />
        <hr style={{ backgroundColor: "white" }} />
        <br />

        <Typography className={classes.blackText}>Mexico:</Typography>
        <Typography className={classes.whiteText}>
          {chooseLang()[1]}
          <Typography className={classes.location}>
            Tapachula, Chiapas
          </Typography>
        </Typography>

        <br />
        <hr style={{ backgroundColor: "white" }} />
        <br />

        <Typography className={classes.blackText}> Honduras:</Typography>
        <Typography className={classes.whiteText}>
          {chooseLang()[2]}
          <Typography className={classes.location}>
            La Ceiba, San Pedro Sula
          </Typography>
        </Typography>
        <hr style={{ backgroundColor: "white" }} />

        <div style={{ marginTop: "50px", textAlign: "center" }}>
          <Typography>
            <div className={classes.blackIcons}>
              <EmailIcon className={classes.blackIcons} />
            </div>
            <Typography className={classes.linkText}>
              <ShakeRotate>
                <Link
                  className={classes.linkText}
                  href="mailto:info@junglefruit.net "
                >
                  <h5 className={classes.linkText}>info@junglefruit.net</h5>
                </Link>
              </ShakeRotate>
            </Typography>
          </Typography>
          <br />
          <div>
            <div className={classes.blackIcons}>
              <PhoneIcon className={classes.blackIcons} />
            </div>
            <div className={classes.linkText}>
              <ShakeRotate>
                <Link className={classes.linkText} href="tel: +1 781 492 6128">
                  <h5 className={classes.linkText}>+1 781 492 6128</h5>
                </Link>
              </ShakeRotate>
            </div>
          </div>
          <br />
          <div>
            <div className={classes.blackIcons}>
              <LanguageIcon className={classes.blackIcons} />
            </div>
            <div className={classes.linkText}>
              <ShakeRotate>
                <Link className={classes.linkText} href="www.junglefruit.net">
                  <h5 className={classes.linkText}>www.junglefruit.net</h5>
                </Link>
              </ShakeRotate>
            </div>
          </div>

          {/* <div>
            <div className={classes.blackIcons}></div>
            <div className={classes.linkText}></div>
          </div>
          <div>
            <div className={classes.blackIcons}></div>
            <div className={classes.linkText}></div>
          </div> */}
        </div>
      </CardContent>
    </Card>
  );
}
