import React, { useState } from "react";

/// components::::

import NavBar2 from "./myComponents/appTemplate/NavBar2";
import { AppContext } from "./myComponents/utilities/AppContext";
import Routes from "./myComponents/utilities/Routes";

function App() {
  const [engEsp, setengEsp] = useState(true);
  return (
    <div>
      <div>
        <AppContext.Provider value={{ engEsp, setengEsp }}>
          <NavBar2 />
          <Routes />
        </AppContext.Provider>

        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "auto",
            minWidth: "50%",
            minHeight: "50%",
            zIndex: "-100",
          }}
        >
          <img
            src={require("../src/myComponents/img/palmsbackgroundWEB.jpg")}
            style={{
              position: "fixed",
              top: "-50%",
              left: "-50%",
              minWidth: "200%",
              minHeight: "200%",
              opacity: "50%",

              zIndex: "-99",
            }}
          />
        </div>

        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "auto",
            minWidth: "50%",
            minHeight: "50%",
            zIndex: "-100",
          }}
        >
          <img
            src={require("../src/myComponents/img/leaves bgWEB.jpg")}
            style={{
              position: "fixed",
              top: "-50%",
              left: "-50%",
              minWidth: "200%",
              minHeight: "200%",
              opacity: "10%",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
