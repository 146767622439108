import React, { useEffect, useState, useContext } from "react";
import RecipeCard from "./RecipeCard";
import { useSpring, animated } from "react-spring";
import { AppContext } from "../utilities/AppContext";
import "/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/App.css";
import "./aboutStylesheet.css";

////info:::::

let titles = [
  ["Grilled-Rambutan Cocktail", "Cóctel de rambután a la parrilla"],

  ["Asian Tropical Fruit Salad", "Ensalada asiática de frutas tropicales"],
  [
    "Watermelon, Rambutan, Feta, and Basil Salad",
    "Ensalada de sandía, rambután, queso feta y albahaca",
  ],

  ["Sweet and Spicy Rambutan Cocktail", "Cóctel de rambután agridulce"],
  ["Pineapple & rambutan Smoothy", "Licuado de piña y rambután"],

  ["Rambutan & lime cocktail", "Cóctel de rambután y lima"],

  ["Rambutan martini", "martini de rambután"],

  ["Mango & rambutan frappe", "Licuado de rambután y mango"],

  ["Honeydew, mint and rambutan frappe", "Licuado de melon, menta y rambután"],

  ["Tequila Slime Shooters", "shots de rambutan y tequila"],
];

let ingredientsEng = [
  [
    [
      "12 rambutans, peeled and seed removed if fresh (canned rambutans come ready to eat)",
      "6 ounces blanco tequila",
      "2 ounces fresh juice from 1 white grapefruit",
      "2 ounces fresh juice from 2 limes",
      "1 ounce simple syrup (see note above)",
      "Smoked sea salt and lime juice for rimming",
    ],
    [
      "12 rambutanes, pelados y sin semillas",
      "6 onzas de tequila blanco",
      "2 onzas de jugo de toronja",
      "2 onzas de jugo de limon",
      "1 onza de jarabe",
      "Sal marina ahumada y jugo de lima para el borde del vaso",
    ],
  ],

  [
    [
      "One can rambutan, drained",
      "One can lychee, syrup reserved",
      "One can longan, drained",
      "1/2 fresh pineapple, cut into chunks",
      "1 mango, cut into chunks",
      "2 kiwi fruits, cut into small chunks",
      "1 pint strawberries",
      "juice of ½ lime",
      "fresh mint leaves, julienned (optional)",
    ],

    [
      "Una lata de rambután",
      "Una lata de lychee",
      "Una lata de logan",

      "1/2 piña fresca, cortada en trozos",

      "1 mango, cortado en trozos",
      "2 kiwis, cortados en trozos pequeños",
      "1 caja de fresas",
      "el jugo de medio limon",
      "hojas de menta",
    ],
  ],

  [
    [
      "1 small shallot, finely minced (about 1 tablespoon)",
      "1 jalapeño, serrano, or thai bird chili, seeded and finely chopped",
      "1 stalk lemongrass, trimmed, bottom 4 inches only, peeled and finely chopped",
      "2 teaspoons honey",
      "1 teaspoon soy sauce",
      "2 teaspoons white wine vinegar",
      "3 tablespoons extra-virgin olive oil",
      "Kosher salt and freshly ground black pepper",
      "2 quarts diced watermelon (about 1/2-inch dice)",
      "1 pound rambutans, peeled and flesh torn into rough chunks (about 1 1/2 cups lychee pieces)",
      "5 ounces crumbled feta cheese",
      "1/2 cup roughly chopped basil leaves",
    ],

    [
      "1 chalote pequeño, finamente picado (aproximadamente 1 cucharada)",
      "1 chile jalapeño, serrano o tailandés, sin semillas y finamente picado",
      "1 tallo de hierba de limón, solo 4 pulgadas inferiores, pelado y finamente picado",
      "2 cucharaditas de miel",
      "1 cucharadita de salsa de soja",
      "2 cucharaditas de vinagre de vino blanco",
      "3 cucharadas de aceite de oliva virgen extra",
      "Sal kosher y pimienta negra recién molida",
      "2 cuartos de galón de sandía picada (aproximadamente dados de 1/2 pulgada)",
      "1 libra de rambutanes, pelados y la carne desgarrada en trozos ásperos (aproximadamente 1 1/2 tazas)",
      "5 onzas de queso feta desmenuzado",
      "1/2 taza de hojas de albahaca picadas",
    ],
  ],

  [
    [
      "12 rambutan, peeled and seed removed",
      "4 jalapeno slice (seed removed if you don't want super spicy) plus",
      "1/2 tsp freshly grated ginger",
      "2-3 tbsp lime simple syrup",
      "4 ounce white or silver rum",
      "2 ounce patron citronage ( or triple sec)",
      "1 cup ice",
      "1 -2 rambutan, cut in 1/2 for garnish",
    ],

    [
      "12 rambután, pelados y sin semillas",
      "4 rebanadas de jalapeño",
      "1/2 cucharadita de jengibre recién rallado",
      "2-3 cucharadas de jarabe de lima",
      "4 onzas de ron blanco o plateado",
      "2 onzas de tripe sec",
      "1 taza de hielo",
      "1 -2 rambután, cortado en 1/2 para decorar",
    ],
  ],

  [
    [
      "200g pineapple, peeled, chopped",
      "5 rambutans, peeled, seeded",
      "1/2 cup (125ml) light coconut cream",
      "A handful of ice cubes",
    ],

    [
      "200 g de piña, pelada, picada",
      "5 rambutanes, peladas, sin semillas",
      "1/2 taza (125 ml) de crema ligera de coco",
      "Un puñado de cubitos de hielo",
    ],
  ],

  [
    [
      "2 teaspoon palm sugar",
      "2 limes cut into 8",
      "5 fresh rambutans, peeled, seeds removed, chopped",
      "60 ml. vodka or bacardi",
      "1/2 cup crushed ice",
    ],

    [
      "2 cucharaditas de azúcar de palma",
      "2 limas cortadas en 8",
      "5 rambutanes frescos, pelados, sin semillas, picados",
      "60 ml. De vodka o ron blanco",
      "1/2 taza de hielo picado",
    ],
  ],

  [
    ["1/2 lime", "1/2 ounce ginger liqueur", "4-6 rambutan (peeled)"],

    ["1/2 limon", "1/2 onza de licor de jengibre", "4-6 rambután (pelados)"],
  ],

  [
    [
      "1 mango cheek, flesh removed",
      "5 rambutans, peeled, seeded",
      "1/2 cup (125ml) light coconut cream",
      "A handful of ice cubes",
    ],

    [
      "1 mejilla de mango, sin carne",
      "5 rambutanes, peladas, sin semillas",
      "1/2 taza (125 ml) de crema ligera de coco",
      "Un puñado de cubitos de hielo",
    ],
  ],

  [
    [
      "200g honeydew melon, peeled, seeded, chopped",
      "5 rambutans, peeled, seeded",
      "1/2 cup (125ml) light coconut cream",
      "5 fresh mint leaves, torn",
      "A handful of ice cubes",
    ],

    [
      "200 g de melón dulce, pelado, sin semillas, picado",
      "5 rambutanes, peladas, sin semillas",
      "1/2 taza (125 ml) de crema ligera de coco",
      "5 hojas de menta fresca",
      "Un puñado de cubitos de hielo",
    ],
  ],

  [
    [
      "1/3 cups Water",
      "1/4 cups Sugar",
      "1/4 cups Fresh Squeezed Lime Juice",
      "2 drops Green Food Coloring",
      "2 Tablespoons White Chia Seeds",
      "1/2 cups Tequila",
      "9 whole Rambutans, For Making Serving Cups",
    ],

    [
      "1/3 tazas de agua",
      "1/4 tazas de azúcar",
      "1/4 tazas de jugo de limón recién exprimido",
      "2 gotas de colorante verde",
      "2 cucharadas de semillas de chía blanca",
      "1/2 tazas de tequila",
      "9 rambutanes enteros, para hacer tazas para servir",
    ],
  ],
];

let directions = [
  [
    [
      "If using the grill: Soak 3 wooden skewers in water for at least 1 hour. Light one chimney full of charcoal. When all the charcoal is lit and covered with gray ash, pour out and arrange the coals on one side of the charcoal grate. Set cooking grate in place, cover grill and allow to preheat for 5 minutes. Alternatively, set half the burners on a gas grill to the highest heat setting, cover, and preheat for 10 minutes. Clean and oil the grilling grate. Place 4 rambutans on each skewer, and grill over high heat until char lines appear and fruit has softened slightly but still holds its shape, about 1 minute per side. Let cool and remove from skewers until ready to use",
      "If using the broiler: Adjust rack to 4 inches below broiler element and preheat broiler to high. Place rambutans on a foil-lined rimmed baking sheet or broiler pan. Broil until softened and lightly charred in spots, turning occasionally, about 5 minutes total. Let cool and remove from skewers",
      "In the bottom of a small pitcher, muddle the rambutans to release juices and break up the fruit. Add tequila, grapefruit juice, lime juice, and simple syrup. Cover and chill for at least an hour up to overnight",
      "To serve, add lime juice to a saucer and smoked salt to another. Dip the side of 4 rocks glasses in lime juice and then gently roll the outside edge in smoked salt. Add ice to each glass and strain the cocktail, dividing equally among the glasses",
    ],
    [
      "Si usa la parrilla: Remoje 3 pinchos de madera en agua durante al menos 1 hora. Encienda una chimenea llena de carbón. Cuando todo el carbón esté encendido y cubierto de ceniza gris, vierta y coloque los carbones en un lado de la rejilla de carbón. Coloque la parrilla de cocción en su lugar, cubra la parrilla y deje precalentar durante 5 minutos. Alternativamente, coloque la mitad de los quemadores en una parrilla de gas a la temperatura más alta, cubra y precaliente por 10 minutos. Limpie y engrase la parrilla. Coloque 4 rambutanes en cada brocheta, y cocine a la parrilla a fuego alto hasta que aparezcan líneas de carbón y la fruta se haya ablandado ligeramente pero aún mantenga su forma, aproximadamente 1 minuto por lado. Deje enfriar y retire de las brochetas hasta que esté listo para usar ",
      "Si usa el asador: Ajuste la parrilla a 4 pulgadas por debajo del elemento del asador y precaliente el asador a temperatura alta. Coloque las rambutanes en una bandeja para hornear con borde de aluminio o en un molde para asar. Ase a la parrilla hasta que estén suaves y ligeramente chamuscadas, girando ocasionalmente, aproximadamente 5 minutos en total . Dejar enfriar y retirar de las brochetas ",
      "En el fondo de una jarra pequeña, mezcle los rambutanes para liberar jugos y rompa la fruta. Agregue tequila, jugo de toronja, jugo de lima y jarabe simple. Cubra y enfríe durante al menos una hora hasta toda la noche",
      "Para servir, agregue jugo de lima a un platillo y sal ahumada a otra. Sumerja el lado de 4 vasos de roca en jugo de lima y luego enrolle suavemente el borde exterior en sal ahumada. Agregue hielo a cada vaso y cuele el cóctel, dividiendo por partes iguales. los lentes",
    ],
  ],

  [
    [
      "To make the pineapple ring (it’s not really a bowl – there is no bottom), lop the green, spiny head off of the pineapple and the bottom stem off. Cut the pineapple into 3-4 pieces, crosswise. For each piece, use a small paring knife to run all along the inside edge of the pineapple. Push out the flesh to use in your salad (you’ll have to also cut the fruit off the tough, center core before adding to the salad). Place the ring on the plate and fill with fruit salad",
      "In a large bowl, combine all of the ingredients and toss. You can add the reserved lychee syrup to the fruit bowl – it’s more of a sweet water, not like a sticky syrup. In fact, any of the syrups from the longan, lychee or rambutan can be used and mixed into other fruit juices or in place of simple syrup in mixed drinks. Try it mixed with sparkling water",
    ],
    [
      "Para hacer el anillo de piña (no es realmente un tazón, no hay fondo), corte la cabeza verde y espinosa de la piña y el tallo inferior. Corte la piña en 3-4 piezas, en forma transversal. Para cada pieza, usa un cuchillo pequeño para pelar todo el borde interior de la piña. Extiende la carne para usar en tu ensalada (también tendrás que cortar la fruta del núcleo duro y central antes de agregarla a la ensalada). anillo en el plato y llenar con ensalada de frutas",
      "En un tazón grande, combine todos los ingredientes y revuelva. Puede agregar el jarabe de lichi reservado al tazón de frutas. Es más un agua dulce, no un jarabe pegajoso. De hecho, cualquiera de los jarabes del longan, el lichi o el rambután pueden usarse y mezclarse con otros jugos de frutas o en lugar de jarabe simple en bebidas mezcladas. Pruébelo mezclado con agua con gas",
    ],
  ],

  [
    [
      "Combine shallot, chili, lemongrass, honey, soy sauce, and vinegar in a small bowl. Slowly drizzle olive oil into bowl while whisking constantly. Season to taste with salt and pepper and set aside",
      "Combine watermelon, rambutans, feta, and basil in a large bowl. Add dressing and toss to combine. Season to taste with more salt as desired. Serve immediately",
    ],
    [
      "Combine la chalota, el chile, la hierba de limón, la miel, la salsa de soja y el vinagre en un tazón pequeño. Rocíe lentamente el aceite de oliva en el tazón mientras bate constantemente. Sazone al gusto con sal y pimienta y reserve",
      "Combine la sandía, las rambutanes, el queso feta y la albahaca en un tazón grande. Agregue el aderezo y revuelva para combinar. Sazone al gusto con más sal según lo desee. Sirva inmediatamente",
    ],
  ],

  [
    [
      "In a measuring cup juice the two limes and top with water to make a total of 1 cup. Pour into a sauce pan and add the 1 cup of organic sugar. Turn on the heat to medium and stir well until the sugar is dissolved. Bring to a simmer and let sit at a simmer for about 10 minutes to slightly thicken. Cool completely before using. Keep in an airtight jar in refrigerator for up to two weeks",
      "Fill two rocks glasses with ice.",
      "In a shaker glass muddle the jalapeños, ginger and rambutans really well to extract as much juice as possible. Add in ice, rum, citronage and 2 Tbsp simple syrup and shake really well. Strain into the two rocks glasses. Garnish with rambutan and jalapeno slices. Taste and add more lime simple syrup for a sweeter drink if desired",
    ],

    [
      "En una taza medidora, jugo de las dos limas y cubra con agua para hacer un total de 1 taza. Vierta en una cacerola y agregue la 1 taza de azúcar orgánica. Encienda el fuego a medio y revuelva bien hasta que el azúcar se disuelva. Llevar a fuego lento y dejar reposar a fuego lento durante unos 10 minutos para que espese un poco. Enfriar completamente antes de usar. Mantener en un recipiente hermético en el refrigerador hasta por dos semanas",
      "Llena dos vasos de rocas con hielo",
      "En un vaso mezclador, mezcle muy bien los jalapeños, jengibre y rambutanes para extraer la mayor cantidad de jugo posible. Agregue hielo, ron, citronage y 2 cucharadas de jarabe simple y agite muy bien. Cuele en los dos vasos de roca. Adorne con rambután y rebanadas de jalapeño. Pruebe y agregue más jarabe de lima simple para una bebida más dulce si lo desea ",
    ],
  ],

  [
    ["Blend the ingredients in a blender until well combined"],

    [
      "Mezcle los ingredientes en una licuadora hasta que estén bien combinados",
    ],
  ],

  [
    [
      "Place palm sugar and limes into a glass. Using the end of a rolling pin, crush until juice has been released from the limes",
      "Add rambutans and continue to crush. Pour into a cocktail shaker along with the vodka and ice. Shake and pour into two glasses. Serve immediately",
    ],
    [
      "Coloque el azúcar de palma y las limas en un vaso. Usando el extremo de un rodillo, aplaste hasta que se haya liberado el jugo de las limas",
      "Agregue rambutanes y continúe triturando. Vierta en una coctelera junto con el vodka y el hielo. Agite y vierta en dos vasos. Sirva inmediatamente",
    ],
  ],

  [
    [
      "In a cocktail shaker, add the rambutans and 2 of the lime quarters",
      "Muddle the limes and rambutans with a muddler or the back of a wooden spoon. Smash up the rambutans, but avoid smashing the pits as much as possible (its easier to strain them out if they aren’t smashed up to bits)",
      "Pour in the vodka and ginger liqueur",
      "Add some ice to the shaker, cover and shake well",
      "Strain into a martini glass and garnish with a lime wheel",
      "serve.",
    ],

    [
      "En una coctelera, agregue las rambutanes y 2 de los cuartos de lima",
      "Mezcle las limas y las rambutanes con un enlodador o la parte posterior de una cuchara de madera. Destroce las rambutanes, pero evite romper los hoyos tanto como sea posible (es más fácil estirarlos si no se rompen en pedazos)",
      "Vierta el vodka y el licor de jengibre",
      "Agregue un poco de hielo al agitador, cubra y agite bien",
      "Colar en una copa de martini y decorar con una rueda de lima",
      "sirva.",
    ],
  ],
  [
    ["Blend the ingredients in a blender until well combined"],

    [
      "Mezcle los ingredientes en una licuadora hasta que estén bien combinados",
    ],
  ],

  [
    ["Blend the ingredients in a blender until well combined"],

    [
      "Mezcle los ingredientes en una licuadora hasta que estén bien combinados",
    ],
  ],

  [
    [
      "Add water and sugar in a small saucepan over medium heat, and swirl until dissolved, about 3–5 minutes. Allow to cool for 5 minutes. Add lime juice, green food coloring and chia seeds. Transfer to a bowl, cover, and refrigerate overnight",
      "Remove from refrigerator. Add tequila, stirring until well incorporated",
      "To make the rambutan cups, take a paring knife and cut through the skin of the rambutan all around, being careful not to cut the flesh of the fruit that lies about 1/8 inch underneath the skin. Remove the fruit, and reserve for another use",
      "Place halves of rambutans on your serving platter, making sure that they’re all level. Stir the shooter mixture again, and transfer to a vessel that you can easily pour out of, like a pyrex glass measuring cup (with a spout)",
      "Carefully pour the shot mixture in the rambutan halves. Serve to your guests",
    ],

    [
      "Agregue agua y azúcar en una cacerola pequeña a fuego medio, y agite hasta que se disuelva, de 3 a 5 minutos. Deje enfriar por 5 minutos. Agregue jugo de limón, colorante verde y semillas de chía. Transfiera a un tazón, cubra y refrigerar durante la noche ",
      "Retirar del refrigerador. Agregar el tequila, revolviendo hasta que esté bien incorporado",
      "Para hacer las tazas de rambután, tome un cuchillo de cocina y corte la piel del rambután alrededor, teniendo cuidado de no cortar la pulpa de la fruta que se encuentra aproximadamente a 1/8 de pulgada debajo de la piel. Retire la fruta y reserve para otro uso ",
      "Coloque mitades de rambutanes en su plato de servir, asegurándose de que estén todos nivelados. Vuelva a mezclar la mezcla del tirador y transfiérala a un recipiente del que pueda verter fácilmente, como una taza medidora de vidrio de pyrex (con un pico)",
      "Vierta cuidadosamente la mezcla de chupito en las mitades de rambután. Sirva a sus invitados",
    ],
  ],
];

let img = [
  "https://www.edamam.com/web-img/10e/10e05acf7af0d62c645c89b9e7a645d3.jpg",
  "https://www.edamam.com/web-img/752/7526238b929717f3031d88966a498067.jpg",
  "https://www.edamam.com/web-img/90a/90a1fd36753a1bd3a13cf7baec92ef11.jpg",
  "https://www.edamam.com/web-img/706/706f73eb71e03b8233fa9d7e5f307ddb.jpg",
  "https://www.edamam.com/web-img/547/547a95a69097693f84230846bb634821.jpeg",
  "https://www.edamam.com/web-img/d19/d19e0d8e1172a9190a2877a1085eef75.jpg",
  "https://www.edamam.com/web-img/a0d/a0dbc9db8f38578c71e0d931e2e0d5b7.jpg",
  "https://www.edamam.com/web-img/678/678d77c555874ec1968c42f895a22f52.jpeg",
  "https://www.edamam.com/web-img/a44/a4412e718949721b919025be8b023864.jpeg",
  "https://www.edamam.com/web-img/21f/21ffd13fe0880b0d80c902c1fce5f781.jpg",
];

let website = [
  "http://www.seriouseats.com/recipes/2015/07/grass-skirt-rambutan-tequila-cocktail-summer-pitcher-drink.html",
  "http://steamykitchen.com/2224-asian-tropical-fruit-salad-recipe.html",
  "http://www.seriouseats.com/recipes/2012/06/watermelon-lychee-feta-and-basil-salad-with-s.html",
  "http://www.pineappleandcoconut.com/recipes/sweet-and-spicy-rambutan-cocktail/",
  "https://www.taste.com.au/recipes/pineapple-rambutan/f381d522-03e0-4ca5-9962-a7e4c0d9e05f",
  "https://www.womensweeklyfood.com.au/recipes/rambutan-lime-cocktail-7032",
  "http://mymansbelly.com/2012/05/18/summer-cocktaili-recipe-rambutan-martini/",
  "https://www.taste.com.au/recipes/mango-rambutan-frappe/58792f74-c900-4568-8cbf-618a50192e4f",
  "https://www.taste.com.au/recipes/honeydew-mint-rambutan-frappe/b41f3a6c-e1e0-47d9-a83f-80bcabbbbe0d",
  "https://tastykitchen.com/recipes/holidays/tequila-slime-shooters/",
];

let serves = [4, 6, 6, 2, 4, 2, 2, 4, 4, 9];

let calories = [593, 974, 2485, 686, 555, 230, 152, 657, 528, 650];

let cardTitles = [
  ["portions", "calories", "recipe"],
  ["porciones", "calorias", "receta"],
];
///////

function RecipesIdeas() {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  const [dimesinoW, setDimensionW] = useState(window.innerWidth);
  const langContext = useContext(AppContext);
  const [titlesLang, setTitlesLang] = useState(0);

  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      setTitlesLang(0);
      console.log("titles", titles);
    } else {
      setTitlesLang(1);
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  useEffect(() => {
    console.log(langContext.engEsp);
    chooseLang();
    // setLang(lang === "falseee" ? "trueee" : "falseee");
  }, [langContext.engEsp]);

  const updateWidthAndHeight = () => {
    setDimensionW(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });
  return (
    <div>
      <header
        className="w3-display-container w3-content w3-wide"
        style={{
          maxWidth: "3000px",
        }}
        id="home"
      >
        <animated.div style={props}>
          <img
            className="w3-image"
            src={require("../img/recipe rambuWEB.jpg")}
            alt="Hamburger Catering"
          />

          <h1
            style={{
              textAlign: "center",
              position: "absolute",
              top: dimesinoW < 680 ? "3.5%" : "15%",
              left: "50%",
              fontFamily: "Staatliches",
              fontSize: dimesinoW < 750 ? "50px" : "120px",
              color: "white",
              transform: "translate(-50%, -50%)",
              textShadow: "2px 2px 4px black",
            }}
          >
            {chooselll(langContext.engEsp)
              ? "Recipe Ideas "
              : "Ideas de recetas"}
          </h1>
        </animated.div>
        <div className="w3-content" style={{ maxWidth: "1100px" }}>
          <div className="w3-row padding-recipeCards " id="about">
            <div>
              <div
                style={{
                  margin: "auto",

                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
                {titles.map((item, i) => (
                  <div key={calories[i] + 4}>
                    <br />

                    <RecipeCard
                      title={item[titlesLang]}
                      image={img[i]}
                      calories={calories[i]}
                      ingredients={ingredientsEng[i][titlesLang]}
                      website={website[i]}
                      serves={serves[i]}
                      directions={directions[i][titlesLang]}
                      multTitles={item}
                      mulIngrid={ingredientsEng[i]}
                      mulDire={directions[i]}
                      cardTitles={cardTitles[titlesLang]}
                    />

                    <br />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default RecipesIdeas;
