import React, { useEffect, useState, useContext } from "react";

import Chance from "chance";
import { animated, config, useSpring } from "react-spring";

import BouncingText from "../views/BouncingText";
var chance = new Chance();
export default function BouncingRambu(props) {
  const springProps = useSpring({
    opacity: 1,

    config: config.stiff,
    from: { opacity: 0 },
  });

  const text = [
    [
      "This Tropical Fruit Tree Can Grow to over 75Feet",
      "Este árbol frutal tropical puede crecer hasta más de 75 pies",
    ],

    [
      "Its Crushed Leaves Make a paste To Nourish Hair",
      "Sus hojas machacadas hacen una pasta para nutrir el cabello",
    ],

    [
      "The Fruit Stimulated Red Blood Cell Production",
      "La fruta estimula la producción de glóbulos rojos",
    ],

    [
      "Its Bark Treats Tongue Disorders",
      "Su corteza trata los trastornos de la lengua",
    ],

    ["In Vietnam it’s called Chom Chom", "En Vietnam se llama Chom Chom"],

    [
      "It’s Peel Has Many Medicinal Uses",
      "Su cáscara tiene muchos usos medicinales",
    ],

    ["It Can Prevent Weight Gain", "Puede prevenir el aumento de peso"],

    [
      "Its Seed Produces Specialty Fat For Cosmetics",
      "Su semilla produce grasas especiales para cosméticos",
    ],

    [
      "Its Hair and Skin Make Specialty Charcoal For Industrial Uses",
      "Su cabello y piel hacen carbón especial para usos industriales",
    ],
  ];

  const imgs = [
    require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/rambu fruit no bgWEB.png"),
    require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/starfruit.png"),
    require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/rambu fruit no bgWEB.png"),
    require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/meloconton.png"),
    require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/dragon fruit.png"),
    require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/rambu fruit no bgWEB.png"),
    require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/magosteen.png"),
    require("/Users/luismiguelascencio/Development/RealLife/pro/junglefruits/src/myComponents/img/passion fruit.png"),
  ];

  return (
    <animated.div style={springProps}>
      <img
        src={
          imgs[
            chance.integer({
              min: 0,
              max: 7,
            })
          ]
        }
        style={{
          userDrag: "none",
          userSelect: "none",
          mozUserSelect: "none",
          webkitUserDrag: "none",
          webkitUserSelect: "none",
          msUserSelect: "none",
          zIndex: "-99",
          position: "fixed",
          width: `${chance.integer({
            min: window.innerWidth / 4,
            max: window.innerWidth / 3.5,
          })}px`,
          top: `${chance.integer({
            min: 20,
            max: window.innerHeight - 10,
          })}px`,
          left: `${chance.integer({
            min: 0,
            max: window.innerWidth,
          })}px`,
          margin: "-1.50px",
        }}
      />
      {chance.integer({
        min: 0,
        max: 6,
      }) /
        2 ===
      0 ? (
        <BouncingText
          text={
            text[
              chance.integer({
                min: 0,
                max: 8,
              })
            ]
          }
          top={chance.integer({
            min: 20,
            max: window.innerHeight - 10,
          })}
          left={chance.integer({
            min: 20,
            max: window.innerWidth / 2,
          })}
        />
      ) : null}
    </animated.div>
  );
}
